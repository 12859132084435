<template>
  <span>
    <v-card class="mt-3 pa-3" v-if="team">
      <v-card-title primary-title class="justify-center">
        <h1 class="text-center mb-3">Mon entreprise ({{ team.name }})</h1>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12" md="6" class="mb-5" v-if="profile.role != 'User'">
            <v-select
              :items="sessions"
              v-model="session"
              return-object
              item-text="name"
              label="Session"
              @change="onSessionSelection"
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="mb-5"
            v-if="profile.role != 'User' && session != null"
          >
            <v-select
              :items="teams"
              v-model="team"
              return-object
              item-text="name"
              label="Équipe"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' : ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' : ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-sheet
              elevation="5"
              class="d-flex align-end justify-center pa-3 ma-auto"
              :style="
                'width: 500px;height: 500px;background-size: cover;background-position: center;background-image: url(\'' +
                team.logoUrl +
                '\')'
              "
            >
              <v-btn @click="logoDialog = true" color="primary">
                <v-icon class="pr-2">mdi-upload</v-icon>
                CHARGER UN LOGO
              </v-btn>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              label="Nom de l'entreprise"
              v-model="team.publicName"
            ></v-text-field>
            <v-text-field
              label="Slogan de l'entreprise"
              v-model="team.slogan"
            ></v-text-field>
            <span class="mt-5 d-flex align-center justify-center">
              <v-btn color="primary" outlined @click="saveTeam">
                <v-icon class="pr-2">mdi-floppy</v-icon>
                Sauvegarder
              </v-btn>
            </span>

            <v-divider class="my-5"></v-divider>

            <h3 class="mt-5 mb-3">Membres de l’équipe :</h3>
            <v-simple-table v-if="team && team.members && members">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">NOM</th>
                    <th class="text-left">Prenom</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Poste</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="member in members">
                    <td class="text-left" v-text="member.lastname"></td>
                    <td class="text-left" v-text="member.firstname"></td>
                    <td class="text-left" v-text="member.email"></td>
                    <td class="text-left" v-text="member.job"></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <span
              class="mt-5 d-flex align-center justify-center"
              v-if="profile.role == 'User'"
            >
              <v-btn color="primary" outlined @click="jobDialog = true">
                <v-icon class="pr-2">mdi-pencil-outline</v-icon>
                Editer mon poste
              </v-btn>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      class="mt-3 pa-3 mx-auto"
      v-else-if="profile.role == 'User'"
      max-width="600"
    >
      <v-card-title primary-title class="justify-center">
        <h1 class="text-center mb-3">Dashboard</h1>
      </v-card-title>
      <v-card-text>
        <p>Bonjour,</p>
        <p>vous n'etes actuellement lier a aucune équipe !</p>
        <p>
          Rejoignez votre équipe en cliquant sur le bouton "Profil" en haut à
          droite de votre écran et en saisissant le code d'équipe fourni par
          votre intervenant.
        </p>
      </v-card-text>
    </v-card>
    <v-card class="mt-3 pa-3 mx-auto" v-else max-width="600">
      <v-card-title primary-title class="justify-center">
        <h1 class="text-center mb-3">Choisissez une équipe</h1>
      </v-card-title>
      <v-card-text>
        <v-select
          :items="sessions"
          v-model="session"
          return-object
          item-text="name"
          label="Session"
          @change="onSessionSelection"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <v-select
          :items="teams"
          v-model="team"
          return-object
          item-text="name"
          label="Équipe"
          v-if="session != null"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title
                v-text="item.name + ' : ' + item.publicName"
              ></v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ item }">
            <v-list-item-content>
              <v-list-item-title
                v-text="item.name + ' : ' + item.publicName"
              ></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </v-card-text>
    </v-card>

    <v-dialog v-model="jobDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Changer mon poste</v-card-title>
        <v-card-text>
          <v-text-field label="Nouveau poste" v-model="newJob"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="jobDialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="editJob">Sauvegarder</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="logoDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Changer le logo de l'entreprise</v-card-title
        >
        <v-card-text>
          <v-file-input
            color="primary"
            accept=".png,.jpg,.svg"
            truncate-length="32"
            label="Nouveau logo"
            v-model="newLogo"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="logoDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="editLogo">Sauvegarder</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import Swal from "sweetalert2/dist/sweetalert2.js";

import Team from "@/classes/Team.js";
import Univers from "@/classes/Univers.js";
import Profile from "@/classes/Profile.js";
import Session from "@/classes/Session.js";

export default {
  name: "Home",
  props: ["user", "profile", "userSession"],
  setup() {
    return {
      storage: getStorage(),
    };
  },
  data() {
    return {
      unsub: [],
      teams: [],
      sessions: [],
      jobDialog: false,
      logoDialog: false,
      team: null,
      session: null,
      newJob: "",
      newLogo: null,
      members: [],
    };
  },
  async created() {
    if (this.profile && this.profile.team) {
      this.unsub.push(
        Team.listenById(this.profile.team, async (team) => {
          this.team = team;
          console.log("this.team", this.team);
          this.onTeamUpdate();
        })
      );
    }
    if (this.profile && this.profile.role != "User") {
      this.unsub.push(
        Session.listenOnlySession((sessions) => {
          sessions.sort((a, b) => b.date - a.date);
          this.sessions = sessions;
          this.$forceUpdate();
        })
      );
    }
  },
  watch: {
    team: {
      handler: function (newVal, oldVal) {
        this.onTeamUpdate();
      },
    },
  },
  methods: {
    async onSessionSelection() {
      this.teams = [];
      this.team = [];

      let universes = await Univers.getBySession(this.session.id);
      let tmp_teams = [];

      for (let univers of universes) {
        let teams = await Team.getByUnivers(univers.id);
        tmp_teams = tmp_teams.concat(teams);
      }
      this.teams = tmp_teams;
      this.teams.sort((a, b) => a.name.localeCompare(b.name));
      this.$forceUpdate();
    },
    async onTeamUpdate() {
      let tmp_team = this.team;
      this.members = [];
      for (let member of tmp_team.members) {
        let tmp_member = {};

        let tmp_profile = await Profile.getById(member.id);

        if (tmp_profile == null) {
          // Skip this member since their profile doesn't exist
          console.error(`Profile with id ${member.id} not found`);
          continue;
        }

        tmp_member.id = member.id;
        tmp_member.job = member.job;
        tmp_member.firstname = tmp_profile.firstname;
        tmp_member.lastname = tmp_profile.lastname;
        tmp_member.email = tmp_profile.email;

        if (this.members.find((m) => m.id == tmp_member.id) == undefined) {
          this.members.push(tmp_member);
        }
      }
      if (tmp_team.logo && tmp_team.logo.trim() != "") {
        let fileRef = ref(this.storage, tmp_team.logo);
        tmp_team.logoUrl = await getDownloadURL(fileRef);
      }

      this.team = tmp_team;
      this.$forceUpdate();
    },
    editJob() {
      for (let member of this.team.members) {
        if (member.id == this.profile.id) {
          member.job = this.newJob;
          this.team.save();
          this.jobDialog = false;
          break;
        }
      }
    },
    saveTeam() {
      this.team.save();
      Swal.fire({
        icon: "success",
        title: "Sauvegarde effectuée",
        confirmButtonText: "Ok",
      });
    },
    // async editLogo() {
    //   if (this.newLogo && this.newLogo.constructor.name == "File") {
    //     let name =
    //       this.team.name.trim().replace(" ", "-") +
    //       "_" +
    //       this.team.publicName.trim().replace(" ", "-") +
    //       "." +
    //       this.newLogo.name.split(".").pop();
    //     let fileRef = ref(this.storage, "teamLogo/" + name);
    //     await uploadBytes(fileRef, this.newLogo);
    //     this.team.logo = "teamLogo/" + name;
    //     await this.team.save();
    //     this.logoDialog = false;
    //     this.newLogo = null;

    //     let tmp_team = this.team;
    //     if (tmp_team.logo && tmp_team.logo.trim() != "") {
    //       let fileRef = ref(this.storage, tmp_team.logo);
    //       tmp_team.logoUrl = await getDownloadURL(fileRef);
    //     }
    //     // this.team = tmp_team;
    //     // this.$forceUpdate();
    //   }
    // },
    sanitizeFilename(name) {
      return name.replace(/[^a-z0-9_\-\.]/gi, "-");
    },
    async editLogo() {
      if (this.newLogo && this.newLogo.constructor.name === "File") {
        let sanitizedTeamName = this.sanitizeFilename(this.team.name.trim());
        let sanitizedPublicName = this.sanitizeFilename(
          this.team.publicName.trim()
        );
        let fileExtension = this.newLogo.name.split(".").pop();
        let name = `${sanitizedTeamName}_${sanitizedPublicName}.${fileExtension}`;
        let fileRef = ref(this.storage, "teamLogo/" + name);
        await uploadBytes(fileRef, this.newLogo);
        this.team.logo = "teamLogo/" + name;
        await this.team.save();
        this.logoDialog = false;
        this.newLogo = null;

        if (this.team.logo && this.team.logo.trim() !== "") {
          let fileRef = ref(this.storage, this.team.logo);
          this.team.logoUrl = await getDownloadURL(fileRef);
        }
      }
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
